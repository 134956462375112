import React from "react"
import { css } from '@emotion/core';
import Layout from "../component/layout"
import { colors, breakpointsMapMax as mediaQuery } from "../styles/theme"

export default ({ data }) => {
  return (
    <Layout>
        <div
            css={css`
                margin-top: 5rem;
                display: grid;
                grid-template-columns: 2fr 1fr;
                grid-template-rows: 400px;

                ${mediaQuery[2]} {
                    margin: 5rem auto;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 2.5fr 1fr;
                }
            `}
        >
        <span
            css={css`
                font-family: proxima-nova, sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 15rem;
                color: ${colors.textDark};
                padding: 0 0rem;
                align-self: center;
                border-right: 1px solid ${colors.textDark};

                ${mediaQuery[2]} {
                    font-size: 5rem;
                    padding: 0 0;
                    border-right: none;
                    border-bottom: 1px solid ${colors.textDark};
                    justify-self: center;
                }
            `}
        >404</span>
        <span
            css={css`
                font-family: proxima-nova, sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 1.75rem;
                color: ${colors.textDark};
                justify-self: center;
                align-self: center;

                ${mediaQuery[2]} {
                    font-size: 0.6rem;
                    color: ${colors.textDark};
                }
            `}
        >Page not found!</span>
      </div>
    </Layout>
  )
}
